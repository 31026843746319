import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { Container, Form, Col, InputGroup, Row, Button } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Link } from 'gatsby';

const ContainerTitle = styled.section`
    background-color: #1E2E48;
    padding:2.5% 2.5%;
    margin-top: 7rem;
`
const P = styled.p`
    text-align: justify;
    color:#fff;
    padding-top: 2%;

`
const H2 = styled.h2`
    text-align: center;
    color:#fff;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`
const FormContact = styled.section`
    button{
            border-radius: 0;
            background-color: #1D2146 !important;
            border-color: #1D2146 !important;
            padding:10px 50px;
            cursor:pointer;
            z-index: 999 !important;
    }
`

const Index = () => {

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        formData()

    };

    const [formdata, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    })

    const { name, email, phone, message } = formdata;

    const readFormData = (e) => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })
    }

    const formData = () => {
        // console.log(formdata)
    }


    return (
        <FormContact>
            <ContainerTitle>
                <H2>Contacto</H2>
            </ContainerTitle>
            <Container>
                <nav aria-label="breadcrumb" className="mt-5">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a style={{ color: "#000", textDecoration: "none" }} href="/">INICIO</a></li>
                        <li className="breadcrumb-item active" aria-current="page">CONTACTO</li>
                    </ol>
                </nav>
                <H2 style={{ color: "#1E2E48" }} className="mt-5">¿Tienes una pregunta?</H2>
                <H3>DEJANOS TU CONSULTA EN EL FORMULARIO Y NOS COMUNICAREMOS.</H3>
                <Row className="justify-content-center mt-5">
                    <Col lg={5} xs={12}>
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                    <Form.Label>NOMBRE</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Nombre"
                                        required
                                        name="name"
                                        onChange={readFormData}
                                        style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Nombre requerido
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                    <Form.Label>EMAIL</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        required
                                        name="email"
                                        onChange={readFormData}
                                        style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Email requerido
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                    <Form.Label>TELEFONO</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Telefono"
                                        required
                                        name="phone"
                                        onChange={readFormData}
                                        style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Telefono requerido.
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="validationCustom03" className="mt-3">
                                    <Form.Label>MENSAJE</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        placeholder="Mensaje"
                                        required
                                        name="message"
                                        onChange={readFormData}
                                        style={{ borderRadius: "0px", backgroundColor: "#F0F3F4" }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Mensaje requerido.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    required
                                    label="Acepto los terminos y condiciones"
                                    feedback="Debes estar de acuerdo antes de enviar."
                                    feedbackType="invalid"
                                />
                            </Form.Group>
                            <button type="submit" className="btn btn-primary mt-4">ENVIAR</button>
                        </Form>

                        <hr className="mb-0 mt-5" />
                        <P className="text-dark text-center">
                            <span className="me-1">
                                <Link style={{ textDecoration: "none", color: "#212529" }} href="tel:+54 9 3794 653436">
                                    +54 9 3794 653436
                                </Link>
                            </span>
                            -
                            <span className="ms-1">
                                <Link style={{ textDecoration: "none", color: "#212529" }} href="mailto:elsanjuanvn@gmail.com">
                                    ELSANJUANVN@GMAIL.COM
                                </Link>
                            </span>
                        </P>
                        <hr className="mb-0" />
                    </Col>
                </Row>
            </Container >
        </FormContact >
    );
}

export default Index;