import React from 'react';
import SEO from '../structure/seo';
import Layout from '../structure/layout';
import Index from '../structure/pages/contact/index';


const Contact = () => {
    return (
        <Layout shadowNav={false}>
            <SEO title={'Contacto'} />
            <Index />
        </Layout>
    );
}

export default Contact;